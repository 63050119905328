import React from "react"
import { graphql } from "gatsby"
import PageWrapper from "../components/Layout/PageWrapper"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { Container, Col } from "react-bootstrap";
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import SEO from "../components/seo";
import { Bold, EmbeddedEntry, Image, List, Text } from "../components/ContentfulComponents";

const BlogTemplate = ({ data }) => {
    const { title, content } = data.blog;

    const options = {
        renderMark: {
            [MARKS.BOLD]: text => <Bold>{text}</Bold>
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
            if (node.content[0].value === '\\n') {
                return <br />
            } else {
                return <Text>{children}</Text>}
            },
            [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
                const { title, description } = node.data.target.fields;
                return <EmbeddedEntry title={title["en-US"]} description={description["en-US"]} />
            },            
            [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
                const { title, file } = node.data.target.fields;
                return <Image title={title["en-US"]} file={file["en-US"]} />
            },
            [BLOCKS.UL_LIST]: (node, children) => {
                return <List>{children}</List>
            },
            [BLOCKS.HEADING_6]: (node, children) => {
                return <h6 className="pt-3">{children}</h6>
            },
            [INLINES.EMBEDDED_ENTRY]: (node, children) => {
                const { title, description } = node.data.target.fields;
                return <EmbeddedEntry title={title["en-US"]} description={description["en-US"]} />
            },
            [INLINES.ENTRY_HYPERLINK]: (node, children) => {
                const slug = node.data.target.fields.slug["en-US"]
                return <a className="a-home" href={`localhost:8000/recipes/${slug}`} target="_blank" rel="noopener noreferrer">{children}</a>
            },
            [INLINES.HYPERLINK]: (node, children) => {
                return <a className="a-home" href={node.data.uri} target="_blank" rel="noopener noreferrer">{node.content[0].value}</a>
            }
        },
    };
    return (
        <PageWrapper>
            <SEO title={title} />
            <Container className="d-flex justify-content-center under-nav" fluid>
                <Col lg={8}>
                    <h1 className="text-center">{title}</h1>
                    <Container fluid className="pt-5">
                        {documentToReactComponents(content.json, options)}
                    </Container>
                    <Container className="pt-5 pb-5 d-flex justify-content-center">
                        <AniLink fade to="/blog/" className="btn-blue">Terug naar alle blogs</AniLink>
                    </Container>
                </Col>
            </Container>

        </PageWrapper>
    )
}

export default BlogTemplate;

export const query = graphql`
query ($slug: String!) {
  blog: contentfulBlog(slug: { eq: $slug }) {
    title
    date(formatString: "DD MMMM YYYY", locale: "nl")
    content {
        childContentfulRichText {
          html
        }
        json
      }
  }
}
`;


