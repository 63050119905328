import React from "react"

export const Bold = ({ children }) => <b className="font-weight-bold">{children}</b>;

export const Text = ({ children }) => <p className="align-center">{children}</p>;

export const List = ({ children }) => <li className="pb-3 pt-3">{children}</li>;

export const EmbeddedEntry = ({ title, description }) => (
    <div>
        <h2>{title}</h2>
        <p>{description}</p>
    </div>
);

export const Image = ({ title, file }) => (
    <img className="img-fluid my-3" src={file.url} alt={title} />
);